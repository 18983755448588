.sidebarContainerCard {
  background: #0f0f0f;
  border: 1px solid #2c2c2d;
  width: 336px;
  height: 322px;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  padding: 16px;
}

@media (max-width: 577px) {
  .sidebarContainerCard {
    width: auto;
  }
}

.sidebarContainerCard button {
  width: 296px;
  height: 32px;
  border-radius: 8px;
  border-color: #d0a059;
  padding: 0px, 16px, 2px, 16px;
  gap: 12px;
  margin-top: 18px;
}

.subtitle {
  margin-bottom: 16px;
  font-size: 14px;
  font-family: Inconsolata;
  font-weight: 400;
  line-height: 14.69px;
  color: #a3a3a4;
}

.sideCardHeader {
  width: 296px;
  height: 78px;
  border-bottom: 1px solid #2c2c2d;
  font-weight: 700;
  display: flex;
  gap: 16px;
  align-items: center;
  
}
.sideCardHeader svg {
  width: 24px;
  height: 24px;
}

.title {
  font-size: 14px;
  margin-bottom: 8px; 
  color: #f5eee6;
  font-family: EB Garamond;
  font-weight: 700;
  line-height: 23.49px;
}

.icon {
  width: 36px;
  height: 36px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover; 
  background-position: center; 
}

.purpleIcon {
  background: var(--brand-purple-hint);
  border: 1px solid var(--brand-purple-semi-transparent);
}
.purpleIcon svg {
  color: var(--brand-purple);
}

.grayIcon {
  background: var(--brand-gray-hint);
  border: 1px solid var(--brand-gray-semi-transparent);
}
.grayIcon svg {
  color: var(--brand-gray);
}
.blackIcon {
  border: 1px solid var(--border-med);
}

.sideCardBody {
  font-size: 14px;
  color: #f5eee6;
  margin-bottom: 8px;
  margin-top: -16px;
}

@media (max-width: 1200px) {
  .sidebarContainerCard {
    flex: 1;
    min-width: 250px !important;
    height: max-content !important;
  }
}
