.readMoreContainer {
  padding-bottom: 15px;
}
.readMoreLessButton {
  display: block !important;
  color: var(--brand-common-yellow) !important;
  text-decoration: underline;
  font-weight: 600;
  width: max-content;
  margin-top: 10px;
}
.readMoreLessButton:hover {
  cursor: pointer;
}

.readMoreText {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(4 * 1.5 * 16px);
  font-size: 16px;
  line-height: 1.5;
  color: #F5EEE6;
}

.clampLine {
  -webkit-line-clamp: unset;
  max-height: none;
}
