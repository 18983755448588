.btn {
  border-radius: 10px;
  padding: 0.575rem 0.65rem 0.525rem;
  padding: 6px 12px !important;
  white-space: normal;
  text-align: center;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 140%;
}
.btn div {
  margin: auto;
}

.btn:disabled {
  background-color: var(--brand-gray);
}

/* PINK BG */

.btnPinkBg {
  background-color: var(--brand-pink);
  border: none;
}
.btnPinkBg:hover {
  background-color: var(--brand-pink-semi-transparent);
}
.btnPinkBg:active,
.btnPinkBg:focus {
  background-color: var(--brand-pink-semi-transparent);
  box-shadow: 0 0 0 0.25rem var(--brand-pink-transparent) !important;
}

/* PURPLE BG */

.btnPurpleBg {
  background-color: var(--brand-common-yellow);
  color: #212529;
  border: none;
}
.btnPurpleBg:hover {
  background-color: var(--brand-common-yellow);
  color: #212529;
}
.btnPurpleBg:active,
.btnPurpleBg:focus {
  background-color: var(--brand-common-yellow);
  box-shadow: 0 0 0 0.25rem var(--brand-common-yellow) !important;
  color: #212529;
}

/* PURPLE LIGHT BG */

.btnPurpleLightBg {
  background-color: var(--brand-purple-hint);
  color: var(--brand-purple);
  border: none;
}
.btnPurpleLightBg:hover {
  background-color: var(--brand-purple-semi-transparent);
  background-color: var(--brand-purple-hint);
  color: var(--brand-purple);
}
.btnPurpleLightBg:active,
.btnPurpleLightBg:focus {
  background-color: var(--brand-purple-hint);
  color: var(--brand-purple);
}

/* WHITE BG */

.btnWhiteBg {
  background-color: white;
  color: black;
  border-color: rgba(0, 0, 0, 0.1);
}

.btnWhiteBg:hover {
  color: gray;
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.btnWhiteBg:active,
.btnWhiteBg:focus {
  color: gray !important;
  background-color: var(--brand-gray-semi-transparent);
  box-shadow: 0 0 0 0.25rem var(--brand-gray-transparent) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}

/* YELLOW BG */

.btnYellowBg {
  color: white;
  background-color: var(--brand-yellow);
  border-color: var(--brand-yellow);
  opacity: 1;
}
.btnYellowBg:disabled {
  border-color: var(--brand-gray);
  background-color: var(--brand-gray);
}

.btnYellowBg:hover {
  color: white;
  background-color: var(--brand-yellow-transparent) !important;
  border-color: var(--brand-yellow-transparent) !important;
}
.btnYellowBg:active,
.btnYellowBg:focus {
  color: gray !important;
  background-color: var(--brand-yellow-transparent);
  box-shadow: 0 0 0 0.25rem var(--brand-gray-transparent) !important;
  border-color: var(--brand-yellow-transparent) !important;
}

/* WHITE-YELLOW BG */

.btnWhiteYellowBg {
  color: var(--brand-yellow);
  background-color: white;
  border-color: var(--brand-yellow);
}

.btnWhiteYellowBg:hover {
  color: white;
  background-color: var(--brand-yellow-transparent) !important;
  border-color: var(--brand-yellow-transparent) !important;
}
.btnWhiteYellowBg:active,
.btnWhiteYellowBg:focus {
  color: gray !important;
  background-color: var(--brand-yellow-transparent);
  box-shadow: 0 0 0 0.25rem var(--brand-gray-transparent) !important;
  border-color: var(--brand-yellow-transparent) !important;
}

/* GREEN BG */

.btnGreenBg {
  background-color: #0f0f0f !important;
  border: 1px solid;
  color: #d0a059;
}
.btnGreenBg:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  border-color: #d0a059 !important;
}
.btnGreenBg:active,
.btnGreenBg:focus {
  background-color: var(--brand-green-semi-transparent);
  box-shadow: 0 0 0 0.25rem var(--brand-green-semi-transparent) !important;
}

/* RED BG */

.btnRedBg {
  background-color: var(--brand-red-hint) !important;
  color: var(--brand-red) !important;
  border: none;
}
.btnRedBg:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: var(--brand-red-semi-transparent) !important;
}
.btnRedBg:active,
.btnRedBg:focus {
  background-color: var(--brand-red-semi-transparent);
  box-shadow: 0 0 0 0.25rem var(--brand-red-semi-transparent) !important;
}

/* GRAY BG */

.btnGrayBg {
  background: var(--border-light) !important;
  border: none;
  color: var(--brand-gray);
}

.btnGrayBg:hover {
  color: var(--brand-gray-semi-transparent);
}

.btnGrayBg:active,
.btnGrayBg:focus {
  color: var(--brand-gray);
}

.btnGrayBg:disabled {
  color: var(--brand-gray-semi-transparent);
}
