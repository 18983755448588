@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Bold.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Medium.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Medium.woff') format('woff');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Regular.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('./assets/fonts/PT-Root-UI_Light.woff2') format('woff2'),
    url('./assets/fonts/PT-Root-UI_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}



/**
 * title
 */
@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EB-Garamond_Regular.woff2') format('woff2'),
  url('./assets/fonts/EB-Garamond_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('./assets/fonts/EB-Garamond_Bold.woff2') format('woff2'),
  url('./assets/fonts/EB-Garamond_Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}




@font-face {
  font-family: 'Inconsolata';
  src: url('./assets/fonts/Inconsolata_Regular.woff2') format('woff2'),
  url('./assets/fonts/Inconsolata_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('./assets/fonts/Inconsolata_Bold.woff2') format('woff2'),
  url('./assets/fonts/Inconsolata_Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family: 'Inconsolata';
  src: url('./assets/fonts/Inconsolata_Light.woff2') format('woff2'),
  url('./assets/fonts/Inconsolata_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('./assets/fonts/Inconsolata_Medium.woff2') format('woff2'),
  url('./assets/fonts/Inconsolata_Medium.woff') format('woff');
  font-weight: medium;
  font-style: normal;
}









@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid&display=swap');

* {
  font-family: 'Inconsolata';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  /*letter-spacing: -0.2px;*/
  background-color: black;
}

.bgGray {
  background: #111111;
}
.bgWhite {
  background-color: #1A1A1B;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
}

@media (max-width: 575px) {
  .wrapper {
    max-width: 100%;
  }
  .navbar-light .navbar-toggler-icon {
    background-image:url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3%206C3%205.44772%203.44772%205%204%205H20C20.5523%205%2021%205.44772%2021%206C21%206.55228%2020.5523%207%2020%207H4C3.44772%207%203%206.55228%203%206ZM3%2012C3%2011.4477%203.44772%2011%204%2011H20C20.5523%2011%2021%2011.4477%2021%2012C21%2012.5523%2020.5523%2013%2020%2013H4C3.44772%2013%203%2012.5523%203%2012ZM3%2018C3%2017.4477%203.44772%2017%204%2017H20C20.5523%2017%2021%2017.4477%2021%2018C21%2018.5523%2020.5523%2019%2020%2019H4C3.44772%2019%203%2018.5523%203%2018Z%22%20fill%3D%22%23DDDADA%22%2F%3E%3C%2Fsvg%3E");
  }
}

.homeGradientBg {
  background: url('../src/assets/small-pink-pattern@2x.png'),
    linear-gradient(180deg, rgba(245, 245, 245, 0) 82.54%, #f5f5f5 100%),
    radial-gradient(
      19.94% 82.15% at 100% 0%,
      rgba(203, 167, 39, 0.2) 0%,
      rgba(203, 167, 39, 0) 100%
    ),
    radial-gradient(
      73.64% 129.99% at -5.75% 0%,
      rgba(80, 186, 154, 0.265) 0%,
      rgba(80, 186, 154, 0) 65.01%
    ),
    radial-gradient(
      73.21% 132.35% at 20.6% -17.55%,
      rgba(138, 43, 226, 0.2) 0%,
      rgba(138, 43, 226, 0) 100%
    ),
    radial-gradient(
      84.88% 125.84% at 100% 0%,
      rgba(224, 46, 207, 0.091) 0%,
      rgba(224, 46, 207, 0) 100%
    ),
    radial-gradient(
      84.28% 100% at 50% 0%,
      rgba(138, 43, 226, 0.12) 0%,
      rgba(224, 46, 207, 0.016) 100%
    );
}

.gradientBg {
  background: #1a1a1a
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

.frontinusTitle{
  font-family: 'EB Garamond' ;
  font-weight: 700;
}

.MuiMenu-paper {
  background: #1A1A1B;

}

.MuiMenu-paper .MuiFormControl-root {
  height: 40px;
  width: 300px;
}
.MuiMenu-paper .MuiFormControl-root .MuiInputBase-adornedEnd {
  height: 40px;
  color: #F5EEE6;
}
.MuiMenu-paper .MuiFormControl-root .MuiInputBase-adornedEnd .MuiInputBase-inputAdornedEnd {
  height: 40px;
  color: #F5EEE6;
  padding:0;
  padding-left: 14px;
}


.MuiMenu-list {
  background: #1A1A1B;
  color: #F5EEE6;
}