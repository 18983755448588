.navbar {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.navBarCollapse {
  display: flex;
  align-items: center;
}

@media (max-width: 993px) {
  .navBarCollapse {
    margin-top: 15px;
  }
}

.navbarBrand {
  color: var(--brand-black);
  font-weight: bold;
  font-size: 22px;
  text-decoration: none;
}

.navbarBrand:hover {
  color: var(--brand-black);
}

.navbar-toggler {
  border-radius: 10px !important;
}

@media (max-width: 991px) {
  .menuLink {
    border: 1px solid var(--border-med);
    border-radius: 10px;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }
  .link {
    display: block;
  }
}

.link {
  color: #D6D3D1!important;
  font-weight: 600;
}

.link:hover {
  text-decoration: none;
  color: #D6D3D1!important;
}

.buttonGroup {
  display: flex;
}

.connectBtnContainer {
  align-self: center;
}
.createRoundBtn {
  height: 40px;
  background-color: #1A1A1B!important;
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;

  color: #D0A059!important;
  box-sizing: border-box;

  /* Auto layout */

  flex-direction: row;
  justify-content: center;
  padding: 0px 16px;
  gap: 12px;

  width: 220px;

  border: 1px solid #D0A059!important;
  border-radius: 8px;

  flex-grow: 0;
}
@media (max-width: 991px) {
  /*.menuLink {*/
  /*  background-color: white;*/
  /*}*/

  .buttonGroup {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .buttonGroup div {
    /*width: 100%;*/
  }
  .buttonGroup div button {
    background-color: white;
  }
  .connectBtnContainer div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connectBtnContainer button {
    align-self: center;
  }
  .createRoundBtn {
    color: var(--brand-black-transparent);
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.1) solid !important;
  }
}

@media (min-width: 992px) {
  .divider {
    border: 1px solid var(--border-light);
    border-radius: 1px;
  }
  .link {
    margin-right: 0.75rem;
  }
  .menuLink {
    padding-left: 0rem !important;
  }
  .menuLink:nth-child(2) {
    padding-right: 0rem !important;
  }
}
.poweredByNouns {
  color: var(--brand-black-transparent);
  font-weight: 550;
  font-size: 14px;
  margin-top: -0.3rem;
}

.poweredByNouns a {
  color: var(--brand-pink);
  text-decoration: none;
}

.bulbImg {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
.logoGroup {
  display: flex;
  gap: 10px;
  align-items: center;
}
