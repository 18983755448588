.propCardsRow {
  width: 100%;
}
@media (max-width: 1200px) {
  .propCardsRow {
    display: flex;
    flex-direction: column-reverse;
    width: -webkit-fill-available;
  }
}
