/* Form Styles */
.form {
  margin-top: 3rem;
}

.inputLabel {
  font-size: 18px;
  font-weight: 700;
  color: #f5eee6;
}

.inputGroup {
  margin-bottom: 2rem;
}

/*p {*/
/*  margin-top: 4rem;*/
/*  width: 600px;*/
/*  height: 37px;*/
/*  font-size: 28px;*/
/*  font-weight: bold;*/
/*  color: #f5eee6;*/
/*}*/

.inputSection {
  margin: 3rem 0rem;
}

.inputInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputChars {
  font-size: 14px;
  color: #676b6d;
}

.inputError {
  color: red;
  font-weight: 600;
  padding: 0.5rem 0rem;
  margin-bottom: 0rem;
}

.infRoundSectionsContainer {
  display: flex;
}

.infRoundTitleSection {
  width: 100%;
  padding-right: 3rem;
}

.description {
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  color: #f5eee6;
}

.propEditor {
  display: block;
}

.propEditor .ql-container .ql-editor {
  min-height: 400px!important;
}

.fundReqFormGroup {
  max-width: 8rem;
}

.input {
  border: none;
  font-size: 20px;
  background-color: #272728;
  margin-bottom: 0rem;
  white-space: pre-line;
  border-radius: 4px;
  padding: 0.75rem !important;
  font-size: 16px !important;
  color: #F5EEE6;
}

.input:focus {
  box-shadow: none;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  background: #272728;
  color: #F5EEE6;
}

.input::placeholder {
  color: #676b6d;
  font-size: 16px !important;
  font-style: italic;
  padding-left: 0.5rem;
}

.input:focus::-webkit-input-placeholder {
  opacity: 0 !important;
}

.descriptionLabel {
  margin-bottom: 0;
}

.descriptionInput {
  height: 15rem;
}

.quillArea {
  width: 100%;
}

:global(.editPropContainer) .inputGroup,
:global(.editPropContainer) .inputSection:first-child {
  margin-top: 0 !important;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .infRoundSectionsContainer {
    display: flex;
    flex-direction: column;
  }

  .infRoundTitleSection {
    padding-right: 0rem;
  }
}

/* Loading Overlay */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
