.cardContainer {
  height: 512px;
  width: 1024px;
  background: white;
  background: url('../../assets/noggle-pattern-social.png');
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 70%;
  margin-bottom: 15px;
  display: flex !important;
  color: #abadb8 !important;
  gap: 10px;
}
.houseImg {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  outline: 1px solid var(--border-light);
  min-width: min-content;
  overflow: hidden;
  background: white;
  display: flex;
  align-items: center;
}
.houseImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 44px;
  min-width: 44px;
}
.roundName {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.propNameContainer {
  min-height: inherit;
}
.propName {
  font-weight: 700;
  font-size: 50px;
  line-height: 115%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.userInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}
.proposedBy {
  font-weight: 700;
  font-size: 24px !important;
  line-height: 150%;
  color: var(--brand-gray);
}
.proposerAddress {
  font-weight: 700;
  font-size: 42px !important;
  line-height: 70%;
  color: #14161b;
}
.infoAndImage {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  min-height: 11rem;
  margin-top: 25px !important;
}
.propImgContainer {
  width: 241px;
  height: 241px;
  min-width: 241px !important;
  border-radius: 24px;
  outline: 1px solid var(--border-light);
  min-width: min-content;
  overflow: hidden;
  background: white;
}
.propImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 120px;
  min-width: 120px;
}
