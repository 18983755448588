.houseContainer {
  background: #111111;

  min-height: 350px;
  padding: 1rem;
}

.stickyContainer {
  /*background: white;*/
  /*top: 0;*/
  position: sticky !important;
  z-index: 1;
  padding-top: 12px;
  border-bottom: 1px solid var(--border-light);
}

@media (max-width: 575px) {
  .houseContainer {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .stickyContainer {
    padding: 12px 0px 8px 0px;
  }
}

.sideCards {
  position: sticky;
  top: 4rem;
  align-self: flex-start;
}
.swiper {
  z-index: 0;
  width: 100%;
}

@media (max-width: 1200px) {
  .sideCards {
    display: flex;
    padding: 0;
    gap: 8px;
    margin-top: -16px;

    position: relative;
    top: inherit;
    align-self: inherit;
  }
  .carousel {
    display: flex;
    overflow-x: scroll;
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .breakOut {
    width: 100vw;
    max-width: none !important;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
    height: max-content !important;
  }
}

@media (max-width: 577px) {
  .sideCards {
    padding: 0;
  }
  .breakOut {
    width: 100%;
    position: unset;
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
  }
}


