.pillContainer {
  display: inline-block;
  padding: 2.5px 0.65rem;
  border-radius: 10px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.divider {
  background: var(--border-med);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 1px;
  height: 38%;
  margin: auto;
  margin-bottom: 20px;
}

.filter {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2px solid transparent;
  padding-bottom: 12px;
}
.filter:hover {
  cursor: pointer;
}
.filterText {
  display: flex;
  align-items: center;
  gap: 6px;
}
.filterName {
  font-weight: 600;
  color: var(--text-color);
}



.filterNumber {
  font-weight: 700;
  font-size: 14px;
  /*border-radius: 6px;*/
  /*padding: 0px 2px;*/
  align-self: center;
  background: var(--border-light);
  /*color: var(--text-color);*/
  min-width: 20px;
  text-align: center;
  color: var(--brand-black);
  /*background-color: var(--bg-dark);*/
  background: #313134;
  border-radius: 8px;
  padding: 0 4px;
}




.filter:hover{
  border-bottom: 2px solid var(--brand-common-yellow);
}

.pink.active {
  border-bottom: 2px solid var(--brand-common-yellow);
}
.pink.active .filterName {
  color: var(--brand-common-yellow);
}
.pink.active .filterNumber {
  color: var(--brand-common-yellow);
  background-color: var(--brand-pink-hint);
}

.purple.active {
  border-bottom: 2px solid var(--brand-purple);
}
.purple.active .filterName {
  color: var(--brand-purple);
}
.purple.active .filterNumber {
  color: var(--brand-purple);
  background-color: var(--brand-purple-hint);
}

.green.active {
  border-bottom: 2px solid var(--brand-green);
}
.green.active .filterName {
  color: var(--brand-green);
}
.green.active .filterNumber {
  color: var(--brand-green);
  background-color: var(--brand-green-hint);
}

.black.active {
  border-bottom: 2px solid var(--brand-common-yellow);
}
.black.active .filterName {
  color: var(--brand-common-yellow);
}
.black.active .filterNumber {
  color: var(--brand-black);
  /*background-color: var(--bg-dark);*/
  background: #313134;
  border-radius: 8px;
  padding: 0 4px;
}

.count {
  background: var(--border-light);
  border-radius: 6px;
  color: var(--brand-gray);
  padding: 0px 4px;
  min-width: 18px;
  text-align: center;
}

.filters {
  display: flex;
  gap: 12px;
  height: 45px;
}

@media (max-width: 993px) {
  .filters {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .filters {
    display: none;
  }
}

@media (min-width: 768px) {
  .dropdown {
    display: none;
  }
}
