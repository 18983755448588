body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* i18n switcher */
.dropdown-toggle::after {
  vertical-align: inherit !important;
}
.lang .btn-primary.dropdown-toggle {
  background: transparent !important;
  color: var(--brand-gray) !important;
  border: none !important;
}
.lang .btn-primary.dropdown-toggle:hover {
  color: gray !important;
}
.lang .dropdown-item.active {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
  color: black;
}
@media (max-width: 991px) {
  .lang .btn-primary.dropdown-toggle {
    color: var(--brand-gray) !important;
    border: 1px solid transparent !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    background-color: white !important;
  }
}
.lang .dropdown-menu {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 6%) !important;
  border-radius: 12px !important;
}
.lang .dropdown-item {
  display: flex;
  align-items: center;
}
.lang .dropdown-item img {
  width: 10px;
  display: none;
}
.lang .dropdown-item.disabled img {
  display: flex;
}
.lang .dropdown-item {
  color: var(--brand-gray) !important;
  justify-content: space-between;
}
.lang .dropdown-menu button {
  padding: 4px 12px;
}
.lang .dropdown-item.disabled {
  color: var(--brand-black) !important;
}

/* tooltip */
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: white !important;
}
