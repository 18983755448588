.footerContainer {
  padding: 6rem 0 3rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex: 1 1;
}

.footer {
  display: flex;
  gap: 32px;
  font-size: 18px;
}

.footer a {
  color: #FFFFFF !important;
  text-decoration: underline;
}

@media (max-width: 575px) {
  .footerContainer {
    padding: 4rem 20px 3rem 20px;
  }
}

@media (max-width: 375px) {
  .footer {
    font-size: 14px !important;
  }
}
