.propsContainer {
  max-height: 240px;
  overflow-y: auto;
}
.props {
  gap: 12px;
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
}
.propCopy {
  gap: 10px;
  display: flex;
  overflow-y: auto;
  align-items: center;
  color: var(--brand-gray);
}
.voteCount {
  margin: 0;
  font-size: 20px;
  min-width: 16px;
  font-weight: 700;
  color: var(--brand-purple);
}
.line {
  margin: 0;
  border-radius: 50px;
  height: 2px !important;
  min-width: 18px !important;
  color: var(--brand-gray-semi-transparent);
}
.propTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--brand-black);
}
