.fullPageDragDropContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  backdrop-filter: blur(10px) !important;
  background: rgba(0, 0, 0, 0.18) !important;
}
.fullPageDragDropContainer span {
  font-size: 20px;
  border: 2px dashed var(--border-dark);
  padding: 20px 44px;
  border-radius: 16px;
  font-weight: 600;
  color: var(--brand-black);
}
