.votesRow {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  width: 100%;
  max-width: 100%;
  width: 98%;
}
.votesRow:not(:last-child) {
  border-bottom: 1px var(--bg-dark) solid;
}
.votesAndTitle {
  display: flex;
  color: var(--brand-black);
  max-width: 85%;
}
.voteRowTitle {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin: 0.3rem 0rem;
  gap: 5px;
}
.voteRowTitle span {
  font-size: 16px;
  margin-left: 4px;
}
.propLink {
  font-size: 14px;
  font-weight: bold;
  color: var(--brand-black);
  background-color: var(--bg-light);
  border-color: var(--border-med);
  padding: 8px 12px;
  padding: 4px 8px;
  border-width: 1px;
  border-radius: 10px;
  width: 10%;
  width: min-content;
  max-width: 50px;
}
.propLink:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.propVotes {
  min-width: max-content;
}
.propTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  color: var(--brand-gray) !important;
  width: auto;
}
.votesContainer {
  max-height: 240px;
  overflow-y: auto;
}
.votesContainer::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}
.votesContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}
.votesContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: white;
  background: var(--brand-gray-semi-transparent);
}
@media (max-width: 450px) {
  .propLink {
    max-width: 40px;
  }
}
