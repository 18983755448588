.content {
  cursor: help;
}

.tooltip [class$='inner'] {
  padding: 0.5rem;
  text-align: left;
  background: var(--brand-black);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), var(--shadow-low);
  border-radius: 8px;
  color: black;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.tooltipTitle {
  color: var(--bg-light);
  font-weight: 500;
  display: block;
  text-align: left;
}
