.houseUtilityBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  position: sticky;
  top: 0;
}

.searchBar {
  display: flex;
  align-items: center;
}
.searchIcon {
  position: absolute;
  padding-left: 12px;
  padding-bottom: 3px;
  color: #F5EEE680;
}
.searchBar input {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 9px 16px 8px 40px;
  font-weight: 500;
  height: 38px;
  background: #1A1A1B;
  border: 1px solid #2C2C2D;
  color: var(--brand-white);

}
.searchBar input:active,
.searchBar input:focus:focus-visible {
  outline: none;
  border: 1px solid var(--border-dark) !important;
}
.searchBar input::placeholder {
  color: #F5EEE680;

}

@media (min-width: 768px) {
  .searchBar {
    padding-bottom: 12px;
  }
  .searchBar input {
    min-width: 312px;
  }
}

@media (max-width: 767px) {
  .houseUtilityBar {
    gap: 16px;
    justify-content: space-between;
  }
  .searchBar input {
    min-width: 0px;
    width: 100%;
  }
}
