.inputLabel {
    font-size: 18px;
    font-weight: 700;
    color: var(--brand-black-transparent);
  }
  .inputGroup {
    margin-top: 2rem;
  }
  .inputSection {
    margin: 3rem 0rem;
  }
  
  .inputInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputChars {
    font-size: 14px;
    color: var(--brand-gray);
  }
  .inputError {
    color: red;
    font-weight: 600;
    padding: 0.5rem 0rem;
    margin-bottom: 0rem;
  }
  .input {
    border: none;
    font-size: 20px;
    color: var(--brand-black);
    margin-bottom: 1.75rem;
    white-space: pre-line;
    margin-bottom: 0rem;
    border-radius: 12px;
    border: 1px solid #ccc !important;
    padding: 0.75rem !important;
    font-size: 16px !important;
    background: #272728;
  }
  .nominateText{
    font-size: 28px;
    color: #f5eee6;
    line-height: 36.54px;
    margin-top: 4rem;
    padding-bottom: 8px;
    font-weight: 700;
  }
.nominateDesc{
  font-size: 14px;
  color: #F5EEE6;
  padding-bottom: 1rem;
}
  .input:focus {
    box-shadow: none;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    background: #272728;
  }
  .input::placeholder {
    color: var(--brand-gray-transparent) !important;
    font-size: 16px !important;
    font-style: italic;
    background: #272728;
  }
  .input:focus::-webkit-input-placeholder {
    opacity: 0 !important;
  }
  
  .descriptionLabel {
    margin-bottom: 0;
  }
  .descriptionInput {
    height: 15rem;
  }
  .quillArea {
    width: 100%;
  }
  :global(.editPropContainer) .inputGroup,
  :global(.editPropContainer) .inputSection:first-child {
    margin-top: 0 !important;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .submitButton {
    width: 96px;
    height: 40px;
    background-color: #d0a059;
    color: #111111;
    font-size: 16px;
    padding: 0px, 16px, 0px, 16px;
    border: 1px;
    gap: 12px;
    border-radius: 8px;
    font-weight: 700;
    cursor: pointer;
  }

  .additionalButton{
    width: 56px;
    height: 28px;
    font-size: 16px;
    font-weight: 700;
    color: #a6a9ab;
    background-color: #111111;
    border: none;
  }