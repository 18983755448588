.faqBackground {
  margin-top: 15px;
}

.minTitle {
  font-family: EB Garamond;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  margin: 12px 0;
}

.desc {
  border-left: 4px solid #2C2C2D;
  color: #A3A3A4;
  font-family: Inconsolata;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 12px;
  margin-bottom: 12px;
}

.list {
  font-family: Inconsolata;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #F5EEE6;
}
.listMain {
  display: flex;
}

.dian {
  margin-left: 8px;
  margin-right: 8px;
}

.text {
  font-family: Inconsolata;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #F5EEE6;
  padding-bottom: 20px;
}

.title {
  font-family: EB Garamond;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
padding: 24px 0px 14px 0px;
gap: 16px;
margin-bottom: 22px;
border-bottom: 1px solid #2C2C2D
}

.accordionItem {
  border: none;
  margin: 2rem 0rem;
  background-color: transparent;
}

.accordionItem > * a {
  color: var(--brand-pink) !important;
}

.accordion button:hover {
  color: var(--brand-pink) !important;
  font-weight: bold !important;
}

.accordion button {
  font-size: 1.5rem !important;
  border: none !important;
  font-weight: bold;
  cursor: pointer;
  line-height: normal;
  padding: 0;
}

.accordion > * button:not(.collapsed),
.accordion > * button:focus {
  color: #212529;
  background-color: transparent;
  box-shadow: none !important;
}

/* override bootstrap color */
.accordion button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.searchWrapper {
  margin-top: -40px;
}

@media (max-width: 1200px) {
  .title {
    margin: 0.5rem 0rem 1.5rem 0rem;
  }
  .searchWrapper {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .faqBackground {
    padding-top: 35px;
  }
}
