.sideCardHeader {
  font-weight: 700;
  display: flex;
  gap: 16px;
  align-items: center;
}

.title {
  font-size: 18px;
  margin-bottom: 0;
}

.subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--brand-gray);
}

.bulletList {
  color: #a3a3a4;
  width: 293px;
  height: 135px;
  display: flex;
  line-height: 21px;
  font-size: 14px;
  flex-direction: column;
  gap: 4px;
  padding-top: 8px;
  margin-left: -36px;
}

.bulletList b {
  color: var(--brand-black);
}

.bulletItem {
  display: flex;
  gap: 18px;
}

.bulletItem p {
  margin-bottom: 0;
}

.bullet {
  color: var(--brand-gray-semi-transparent);
  border-radius: 50px;
  min-width: 18px !important;
  height: 2px !important;
  margin: 0;
  margin-top: 10px;
}

.margintop28{
  margin-top: 28px!important;
}