.ethAddress a {
  cursor: pointer;
  text-decoration: none !important;
  color: var(--text-color) !important;
  font-weight: bold;
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.ethAddress a:visited {
  text-decoration: none !important;
  color: inherit;
}

.paper {
  min-width: 20px !important;
}

.avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
