.container {
  margin-top: 2rem;
}
.blackBg{
    background: #121110;
    width: 100%!important;
    max-width: 100%!important;
    padding-top: 40px;
}

.title {
  color: #F5EEE6;
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  /*letter-spacing: 0px;*/
  text-align: left;
}
.desc1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /*letter-spacing: 0px;*/
  text-align: left;
  color:#F5EEE6;
  margin-bottom: 10px;
  border-bottom: 1px solid #272728; 
  padding-bottom: 10px;
}
.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  /*letter-spacing: 0px;*/
  text-align: left;
  color:#F5EEE6;
  margin-bottom: 10px;
}

.alink{
    color: #0d6efd !important;
}

.input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #F5EEE6;
    background-clip: padding-box;
    border: 1px solid #272728;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: #272728;
    overflow: visible;
    box-sizing: border-box;
    scroll-behavior: smooth;

}
.button button {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 8px;
    background: #D0A059;
    border:1px solid #D0A059;
    color: #111111;
    font-size:16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    height: 40px;
    width: 96px;
    text-align: center;
    margin-top: 30px;
    margin-right: calc(var(--bs-gutter-x) * .5);
    margin-left: calc(var(--bs-gutter-x) * .5 - 12px);
    font-family: 'Inconsolata'; 

}

.button button:hover {
    border: none;
    background-color: #F5C27C;
    
}

.labelMargin {
    margin-bottom: 10px;
}

.labelMargin input {
    color: #F5EEE6;
}
.labelMargin svg {
    color: #D0A059;
}

.dateMain {
    width: 60%;
}
.flexDiv {
    display: flex;

}
.xian {
    width: 4px;
    background: #272728;
    margin-left: 6px;

}
.xian1 {
    height: 21px;
    margin-top: -24px; 
}
.xian2 {
    height: 116px;
    margin-top: -24px; 
}
.xian3 {
    height: 116px;
}
.xian4 {
    height: 90px;
}
.xian5 {
    height: 21px;
}
:root {
    --qiu-color-default: radial-gradient(circle, black 30%, #D9D9D9 31%);
    --qiu-color-filled: #D0A059;
  }

.qiu {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: var(--qiu-color-default);
    
}
.qiuDesc.filled {
    color: #D0A059;
  }
.qiuDesc {
    color:  #f5eee6;
    height: 16px;
    line-height: 16px;
    margin-left: 8px;
    font-weight: bold;
    font-size: 14px;
}
.paddingTop {
    padding-top: 65px;
    padding-left: 40px;
}
.paddingTop1 {
    margin-top: -2px;
}
.paddingTop2 {
    margin-top: 91px;
}
.paddingTop3 {
    margin-top: 116px;
}
.paddingTop4 {
    margin-top: 90px;
}

@media (max-width: 577px) {
    .xian1 {
        height: 22px;
        margin-top: 24px;
    }
    .xian2 {
        height: 156px;
        margin-top: 0px;
    }
    .xian3 {
        height: 178px;
    }
    .xian4 {
        height: 133px;
    }
    .xian5 {
        height: 21px;
    }
    .paddingTop1 {
        margin-top: 46px;
    }
    .paddingTop2 {
        margin-top: 156px;
    }
    .paddingTop3 {
        margin-top: 178px;
    }
    .paddingTop4 {
        margin-top: 133px;
    }
}

@media (max-width: 400px) {
    .xian1 {
        height: 22px;
        margin-top: 24px;
    }
    .xian2 {
        height: 178px;
        margin-top: 0px;
    }
    .xian3 {
        height: 178px;
    }
    .xian4 {
        height: 133px;
    }
    .xian5 {
        height: 21px;
    }
    .paddingTop1 {
        margin-top: 46px;
    }
    .paddingTop2 {
        margin-top: 178px;
    }
    .paddingTop3 {
        margin-top: 178px;
    }
    .paddingTop4 {
        margin-top: 133px;
    }
}

.characterCount,
  .characterCount1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #676b6d;  
    text-align: right;
    margin-top: -23px;
  }