.castVote{
    border-radius: 8px;
    border: 1px solid #2C2C2D;
    width: 600px;
    margin-top: 24px;
    padding: 16px 24px;
}

.endDay,.reason{
    color: #676B6D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.disApproveButton {
    cursor: pointer;
    height: 32px;
    background: #272728;
    font-size: 16px;
    color: #F5EEE699;
    border-radius: 8px;
    font-weight: bold;
    padding: 4px 16px;
    border: 1px solid #272728;
    text-align: center;
}

.title{
    color: #F5EEE6;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%; /* 27px */
}

.options{
    margin-top: 4px;
    margin-bottom: 4px;
}



.item{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: 100%;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */

    border-radius: 8px;
    border: 1px solid #2C2C2D;
    background: #1A1A1B;
    height: 40px;
}

.desc{
    padding: 0px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.activeItem{
    border: 1px solid #856433;
    color: #D0A059;

}


.item:hover{
    border: 1px solid #856433;
    color: #D0A059;

}

.approveSvg {
    margin-left: 8px;
}

.voteBtn {
    cursor: pointer;
    background: #D0A059;
    font-size: 16px;
    color: #111111;
    border-radius: 8px;
    font-weight: bold;
    padding: 4px 16px;
    border: 1px solid #D0A059;
    text-align: center;
}

.loadingBtn{
    width: 60px !important;
    height: 24px !important;
    cursor: pointer;
    background-color: #D0A059 !important;
    padding: 4px 16px !important;
    border: 1px solid #D0A059 !important;
    border-radius: 8px !important;
    text-align: center;
}
@media (max-width: 577px) {
    .castVote{
        width: auto;
    }
}




