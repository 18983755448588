.sectionTitle {
  color: var(--brand-black);
  font-weight: bold;
  font-size: 18px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.sectionTitle .icon {
  margin: 0 8px;
}
.loadMoreRoundsBtn {
  margin-top: 2rem;
  width: 100%;
}
.simpleRoundCardWrapper {
  margin: 0;
}
.noMoreMsg {
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin: 3rem 0;
}

@media (max-width: 767px) {
  .simpleRoundCardWrapper {
    margin: 0 15px;
  }
}
