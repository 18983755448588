.card {
  border: 1px solid var(--border-light);
  padding: 1rem 1.25rem;
  margin: 0.75rem 0;
  transition: box-shadow 0.3s;
  box-shadow: none;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.lightPurpleBg {
  background-color: #f5f5f5;
}

.darkPurpleBg {
  background-color: #ece8f0;
}

.whiteBg {
  background-color: var(--bg-light);
}

.borderRadius10 {
  border-radius: 10px;
}

.borderRadius18 {
  border-radius: 18px;
}

.borderRadius24 {
  border-radius: 24px;
}

.onHoverEffect:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}