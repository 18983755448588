.popup {
    position: fixed;
    top: calc(50% - 106px);
    left: calc(50% - 316px); 
    width: 632px;
    height: 212px;
    border: 1px solid #2c2c2d;
    border-radius: 24px;
    background-color: #1a1a1b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-family: 'Garamond', serif;
    font-size: 24px;
    font-weight: 700;
    color: #f5eee6;
    line-height: 31.68px;
    text-align: center;
  }
  
  
  .popupInner {
    width: 592px;
    height: 96px;
    font-size: 24px;
    gap: 17px;
    background-color: #1a1a1b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .text {
    width: 501px;
    height: 64px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31.68px;
    text-align: center;
  }
  
  .popupInner .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    border: 1px solid #2c2c2d;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1b;
    cursor: pointer;
  }
  .closeBtn img {
    width: 14px; 
    height: 14px; 
    
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .popupInner .okBtn {
    width: 96px;
    height: 40px;
    padding: 0 16px;
    background-color: #d0a059;
    border: 1px solid #2c2c2d;
    border-radius: 8px;
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    font-weight: 700;
    color: #111111;
    cursor: pointer;
    margin-top: -2px;
  }

  .buttonGroup .okBtn:hover {
    background-color: #F5C27C;
}
  
  