.fileInput {
  position: relative;
  min-height: 200px;
  height: auto;
  border: 2px dashed var(--border-dark);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-med);
}
.fileInput input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.fileInput:hover {
  background-color: var(--bg-dark) !important;
}
.uploadReadyTitle {
  margin-bottom: 0;
  margin-top: -8px;
  color: var(--brand-black);
  font-weight: 600;
}
.inputLabelContainer {
  text-align: center;
  color: #ccc;
  font-weight: 600;
  padding: 10px;
  font-size: 18px;
}
.uploadImagePicture {
  width: 150px !important;
  margin-bottom: 10px;
}
.inputLabelTitle {
  color: var(--brand-black);
  margin-bottom: 0px;
}
.inputLabelSubtitle {
  font-style: italic;
  font-size: 14px;
}
.inputLabelContainer img {
  width: 100px;
}
.uploadedFilesContainer {
  margin-top: 10px;
  overflow-y: auto;
  max-height: 200px;
}
.fileName {
  font-weight: 700;
  font-size: 14px;
}
.fileSize {
  font-weight: 500;
  font-size: 12px;
  font-style: italic;
}
.uploadedFile {
  margin-top: 0;
  position: relative;
  display: flex;
  margin-bottom: 2px;
  background-color: var(--bg-dark);
  padding: 8px;
  border-radius: 8px;
}
.uploadedFile p {
  margin-bottom: 0;
}
.fileName {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
@media (max-width: 450px) {
  .fileName {
    max-width: 85%;
  }
}

.uploadedFile img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.fileInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 75%;
}
.deleteFile {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
}
.deleteFile:hover {
  background-color: white;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: opacity 0.3s ease;
}
.invalidFileMessage {
  color: var(--brand-red);
  font-weight: 600;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 5px;
}
