.userPropTitle {
  font-weight: 700;
  font-size: 18px;
}

.sideCardHeader {
  font-weight: 700;
  display: flex;
  gap: 16px;
  align-items: center;
}

.subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--brand-gray);
}
.title {
  /*font-size: 18px;*/
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inconsolata;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #F5EEE6;
}

.divider {
  margin: 12px 0px;
}

.votesModuleContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--brand-gray);
  gap: 2px;
}
.textContainer {
  width: 100%;
}
.titleAndVoteBtns {
  display: flex;
  justify-content: space-between;
}
.voteBtn {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-family: system-ui;
  padding: 0px 6px 0px 6px;
  border-radius: 10px;
}
.voteBtn:focus,
.voteBtn:focus-visible,
.voteBtn:active {
  box-shadow: none !important;
  outline: none !important;
}
