.noResultsContainer {
  padding: 4rem 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
}
.cardImage {
  max-width: 145px;
  max-width: 145px;
  margin: auto;
  width: 100%;
}
.message {
  font-size: 28px;
  color: var(--brand-black);
}
