.userPropCard {
  border: 1px solid var(--border-med);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
}

.userPropTitle {
  font-weight: 700;
  font-size: 18px;
}
.userPropInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}
.userPropItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.userPropNounImg {
  display: flex;
  align-items: center;
  max-width: 24px;
}
.userPropNounImg img {
  width: 100%;
}
.userPropText {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
  color: var(--brand-black);
  font-size: 14px;
}
.userPropTextValue {
  color: var(--brand-gray);
}
