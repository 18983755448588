.sidebarContainerCard {
  border: 1px solid var(--border-med);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}

.userPropCard {
  border: 1px solid var(--border-med);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
}

@media (max-width: 767px) {
  .userPropCard {
    min-width: 250px !important;
    height: max-content !important;
  }
}
