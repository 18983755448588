.cardContainer {
  height: 512px;
  width: 1024px;
  background: white;
  background: url('../../assets/noggle-pattern-social.png');
  padding: 80px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardTitle {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  font-weight: 700;
  font-size: 44px;
  line-height: 70%;
  gap: 10px;
  margin-bottom: 15px;
}
.houseName {
  color: #abadb8;
  font-family: 'Londrina Solid';
}
.roundName {
  font-family: 'Londrina Solid';
}
.roundName {
  font-style: normal;
  font-weight: 700;
  font-size: 75px;
  line-height: 115%;
}
.date {
  font-weight: 700;
  color: var(--brand-gray);
  font-size: 26px;
  line-height: 150%;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
}
.infoAndImage {
  display: flex;
  justify-content: space-between;
}
.houseImg img {
  width: 170px;
  height: 170px;
}
.roundInfoContainer {
  display: flex;
  gap: 60px;
}
.roundInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.roundInfo p {
  margin-bottom: 0;
}
.xDivide {
  color: var(--brand-gray-transparent);
  font-weight: 500;
}
.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  color: var(--brand-gray);
}
.subtitle {
  font-weight: 700;
  font-size: 42px;
  line-height: 83%;
  color: #14161b;
}
