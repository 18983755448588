/*.card {*/
/*    margin: 2rem 0;*/
/*}*/

/*.btnContainer {*/
/*    margin-top: 1rem;*/
/*    margin-bottom: 4rem;*/
/*}*/
/*.btnContainer span {*/
/*    color: var(--brand-black-transparent);*/
/*    font-weight: 600;*/
/*}*/

/*.proposalHelperWrapper {*/
/*    margin-top: 2rem;*/
/*}*/
/*.proposalHelperWrapper h1 {*/
/*    color: var(--brand-gray);*/
/*    font-size: x-large;*/
/*}*/
/*.proposalHelperWrapper h1 span {*/
/*    font-weight: bold;*/
/*}*/

/*.tipCard a {*/
/*    text-decoration: underline !important;*/
/*}*/

/*.actionBtn {*/
/*    margin-left: 1rem;*/
/*}*/
/*.title {*/
/*    margin-top: 4rem;*/
/*    font-weight: 700;*/
/*    font-size: 28px;*/
/*    line-height: 34px;*/
/*    text-align: center;*/
/*}*/
/*.proposalTitle {*/
/*    font-size: 1.25rem;*/
/*    font-weight: 500;*/
/*    text-align: center;*/
/*}*/
/*.boldLabel {*/
/*    font-weight: 700;*/
/*}*/
/*.currencyAmount {*/
/*    border: 1px solid blue;*/
/*}*/
/*.fundingCopy {*/
/*    font-size: 1rem;*/
/*    gap: 5px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

.continueBtn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 32px;
    padding: 0px 16px!important;
    border-radius: 8px!important;
    border: 1px solid #D0A059 !important;
    background: #D0A059 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: var(--bg-dark) !important;
    text-transform: none !important;
    font-family: 'Inconsolata' !important;
}

.continueBtn:hover{
    border: 1px solid #D0A059;
    color: var(--bg-dark);

}


.preview{
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nominateText{
    font-size: 28px;
    color: #f5eee6;
    line-height: 36.54px;
    margin-top: 4rem;
    padding-bottom: 8px;
    font-weight: 700;
}
.nominateDesc{
    font-size: 14px;
    color: #F5EEE6;
    padding-bottom: 1rem;
    border-bottom: 1px solid #272728;
}
.labelMargin {
    margin-bottom: 10px;
    margin-top: 14px;
}

.labelMargin input {
    color: #F5EEE6;
    outline: none;
}

.labelMargin svg {
    color: #D0A059;
}


.desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color:#F5EEE6;
    margin-bottom: 6px;
    margin-top: 12px;
}

.characterCount,
.characterCount1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #676b6d;
    text-align: right;
    margin-top: -23px;
}

.input ,.voteType{
    display: block;
    /* height: 40px; */
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #F5EEE6;
    background-clip: padding-box;
    border: 1px solid #272728;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: #272728;
    overflow: visible;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
.input svg {
    fill: #676B6D;
}

.input:before {
    border-bottom: none!important;
}
.input:after {
    border-bottom: none!important;
}
.voteType{
    height: 40px;
}
.options{
    display: flex;
    margin-bottom: 10px;

}

.option{
    width: 95%;
}

.close{
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.addBar{
    width: 120px;
    display: flex;
    cursor: pointer;
}

.timeBlock{
    display: flex;
    margin-top: 20px!important;
    margin-bottom: 32px;
}

.leftTime,.rightTime{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.leftTime{
    margin-right: 20px;
}

.meumMain {
    background: #1A1A1B;
}
.custTime {
    color: #71717A;
    width: 70px;
}
.timeMain {
    display: flex;
    align-items: center;
}
.timeButton {
    margin-left: 70px;
    margin-top: 12px;
}
.back{
    cursor: pointer;
    color:  #A6A9AB;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px; /* 175% */
}

.footer{
    display: flex;
}
.publishBtn{
    margin-right: 20px;
}


.hide{
    display: none;
}