.votesContainer {
  max-height: 20rem;
  overflow-y: auto;
  border-bottom: 1px var(--bg-dark) solid;
}
.votesRow {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
}
.votesRow:not(:last-child) {
  border-bottom: 1px var(--bg-dark) solid;
}
.voteRowTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--brand-black);
  margin-bottom: 0.3rem;
}
.voteRowTitle span {
  font-size: 16px;
  margin-left: 4px;
}
.verifyVoteBtn {
  font-size: 14px;
  font-weight: bold;
  color: var(--brand-black);
  background-color: var(--bg-light);
  border-color: var(--border-med);
  padding: 8px 12px;
  border-width: 1px;
  border-radius: 10px;
  width: 32%;
}
.verifyVoteBtn:hover {
  opacity: 0.75;
}
.verifyVoteBtn:disabled {
  opacity: 0.75;
}
