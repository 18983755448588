.roundUtilityBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  position: sticky;
  top: 0;
  flex-direction: row-reverse;
}

.propHouseDataRow {
  display: flex;
  padding-bottom: 12px;
}

.infoBar {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  position: sticky !important;
  position: fixed;
  top: 0px !important;
  align-self: flex-start;
  height: auto;
}
.propHouseDataRow > *:not([style*='display:none']):last-child {
  border-right: none;
}

@media (max-width: 992px) {
  .propHouseDataRow {
    padding-bottom: 0px;
    padding-bottom: 12px !important;
  }
}

@media (min-width: 767px) {
  .searchBar {
    padding-bottom: 12px;
  }
  .searchBar input {
    min-width: 312px;
  }
}

@media (max-width: 767px) {
  .roundUtilityBar {
    justify-content: space-between;
  }

  .utilitySection:nth-child(2) {
    padding-bottom: 10px;
  }
  .searchBar input {
    min-width: 0px;
    width: 100%;
  }
  .sortToggles {
    display: none;
  }
  .displaySortToggles {
    display: block;
  }
  .propHouseDataRow {
    justify-content: start;
  }
  .propHouseDataRow :nth-child(n + 2) {
    border-right: none;
  }
  .propHouseDataRow :nth-child(n + 3) {
    display: none;
  }
}

@media (max-width: 576px) {
  .infoBar {
    flex-direction: column;
  }
  .roundUtilityBar {
    flex-direction: column-reverse;
    height: auto;
  }
  .utilitySection {
    width: 100%;
  }
  .propHouseDataRow {
    justify-content: start;
  }
  .propHouseDataRow.hideThirdItemOnMobile > *:nth-child(2) {
    border-right: none;
  }
  .propHouseDataRow.hideFourthItemOnMobile > *:nth-child(2) {
    border-right: 1px solid var(--border-light);
  }
  .propHouseDataRow :nth-child(n + 3) {
    display: flex;
  }
  .propHouseDataRow.hideThirdItemOnMobile :nth-child(n + 3) {
    display: none;
  }
  .propHouseDataRow.hideFourthItemOnMobile :nth-child(n + 4) {
    display: none;
  }
}
