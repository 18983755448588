.listTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    /*font-weight: 700;*/
}

.listItem{
    padding: 0px 0px 6px !important;
}

.date{
    font-size: 16px;
    margin-left: 8px;
    color: #676B6D;
    /*font-weight: 700;*/
}



.quill-viewer .ql-container{
    background: unset !important;
}


.listBar{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 16px;
}


.listFilter{
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--brand-proposql-yellow);
    font-size: 16px;
    font-weight: 700;
}


.loadMoreBtn{
    display: flex !important;
    /*textTransform: none !important;*/
    color:var(--brand-common-yellow) !important;
}