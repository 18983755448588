.bottomContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  font-size: 14px;
}
.votesCopyContainer {
  display: flex;
  flex-direction: column;
}

.yourVotesCopy {
  font-weight: bold;
  font-size: small;
  color: var(--brand-black);
}

.disabledVote {
  background: var(--brand-purple-hint);
  color: var(--brand-purple-semi-transparent);
}

.voteBtns {
  display: flex;
  gap: 2px;
  padding-left: 6px;
}

.voteBtn {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-family: system-ui;
  padding: 0px 6px 0px 6px;
  border-radius: 6px !important;
  height: 24px;
  width: 24px;
}
.voteBtn:focus,
.voteBtn:focus-visible,
.voteBtn:active {
  box-shadow: none !important;
  outline: none !important;
}
.activelyAllotting {
  border-radius: 6px;
  outline: 1px solid var(--brand-purple-semi-transparent);
}
.activelyAllotting .votesAllottedDisplay {
  color: var(--brand-purple) !important;
}

.votesAllottedInput {
  font-weight: bold;
  border-width: 0px;
  text-align: center;
  background-color: white;
  color: var(--brand-gray);
  outline: 1px solid var(--brand-gray-semi-transparent);
  border-radius: 6px;
  height: 24px;
  width: 36px;
}
.activelyAllotting .votesAllottedInput {
  color: var(--brand-purple) !important;
}

.content {
  cursor: help;
}

.tooltip [class$='inner'] {
  padding: 0.5rem;
  text-align: center;

  background: var(--bg-light);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), var(--shadow-low);
  border-radius: 8px;
  color: black;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.tooltipTitle {
  color: var(--brand-gray);
  font-size: 14px;
  display: block;
  text-align: center;
}
