.container {
  margin-top: 2rem;
}
.blackBg{
    background: #121110;
    width: 100%!important;
    max-width: 100%!important;
    padding-top: 40px;
}

.title {
  color: #F5EEE6;
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0px;
  text-align: left;
  
}
.desc1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color:#F5EEE6;
  margin-bottom: 10px;
  border-bottom: 1px solid #272728; /* Add this line */
  padding-bottom: 10px;
  margin-top: 4px;
  }
.qLink {
    color: #1C85F1!important;
}
.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color:#F5EEE6;
  margin-bottom: 6px;
  margin-top: 12px;
}
.MuiInputBase-input {
    color: red;
}
.input {
    display: block;
    /* height: 40px; */
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #F5EEE6;
    background-clip: padding-box;
    border: 1px solid #272728;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background: #272728;
    overflow: visible;
    box-sizing: border-box;
    scroll-behavior: smooth;

}
.button button {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 8px;
    background: #D0A059;
    border:1px solid #D0A059;
    color: #111111;
    font-size:16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    height: 40px;
    width: 96px;
    text-align: center;
    margin-top: 30px;
    margin-right: calc(var(--bs-gutter-x) * .5);
    margin-left: calc(var(--bs-gutter-x) * .5 - 12px);
    font-family: 'Inconsolata'; 

}

.button button:hover {
    border: none;
    background-color: #F5C27C;
    
}
.labelMargin {
    margin-bottom: 10px;
    margin-top: '14px',
}

.labelMargin input {
    color: #F5EEE6;
    
}
.labelMargin svg {
    color: #D0A059;
}

.dateMain {
    width: 60%;
    margin-top: -10px;
}
.flexDiv {
    display: flex;

}
.xian {
    width: 4px;
    background: #272728;
    margin-left: 6px;
}
.xian1 {
    height: 21px;
}
.xian2 {
    height: 90px;
}
.xian3 {
    height: 90px;
}
.xian4 {
    height: 21px;
}







:root {
    --qiu-color-default: radial-gradient(circle, black 30%, #D9D9D9 31%);
    --qiu-color-filled: #D0A059;
  }

.qiu {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: var(--qiu-color-default);
    margin-top: -2px;
}
.qiuDesc.filled {
    color: #D0A059;
  }
.qiuDesc {
    color:  #f5eee6;
    height: 16px;
    line-height: 16px;
    margin-left: 8px;
    font-weight: bold;
    font-size: 14px;
    margin-top: -2px;
}

.paddingTop1 {
    margin-top: 20px;
}
.paddingTop2 {
    margin-top: 87px;
}
.paddingTop3 {
    margin-top: 87px;
}

@media (max-width: 577px) {
    .xian1 {
        height: 45px;
    }
    .xian2 {
        height: 130px;
    }
    .xian3 {
        height: 130px;
    }
    .xian4 {
        height: 21px;
    }
    .paddingTop1 {
        margin-top: 44px;
    }
    .paddingTop2 {
        margin-top: 128px;
    }
    .paddingTop3 {
        margin-top: 128px;
    }
}


.paddingTop {
    padding-top: 42px;
    padding-left: 40px;
}
  .characterCount,
  .characterCount1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #676b6d;  
    text-align: right;
    margin-top: -23px;
  }

  .inputContainer {
    margin-top: -10px;
  }
