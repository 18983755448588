.item {
  display: flex;
  font-size: large;
  flex-direction: column;
  border-right: 1px solid var(--border-light);
  padding-right: 25px;
  margin-right: 2rem;
}
.itemTitle {
  color: var(--brand-gray);
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
}

.itemData {
  font-weight: bold;
  font-size: 18px;
  margin-right: 0.5rem;
}
.purpleText {
  color: var(--brand-common-yellow);
}

.displayProgBar {
  display: flex;
  flex-direction: row !important;
}

.progressBar {
  width: 10px;
  height: 90%;
  background-color: var(--brand-green-semi-transparent);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.progress {
  width: 100%;
  background-color: var(--brand-green);
  border-radius: 10px;
  min-height: 0px;
}

@media (max-width: 992px) {
  .item {
    margin-right: 0.75rem;
  }
}
