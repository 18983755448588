.imageLinkInfo {
  padding-bottom: 20px;
}

.modal {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  z-index: 100;
  background: linear-gradient(0deg, #1A1A1B, #1A1A1B),
  linear-gradient(0deg, #2C2C2D, #2C2C2D);

  border: var(--border-light);
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08), 0px 6px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px 24px 0px 0px;
  padding: 0px;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  display: flex;
  flex-direction: column;
  min-width: 70% !important;
  min-height: 90% !important;
  max-height: 90% !important;
  justify-content: space-between;
  top: 55% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.modal::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}
.modal::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 95px;
}
.modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: white;
  background: var(--brand-gray-semi-transparent);
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-bottom: 8px;
}

.imgContainer {
  text-align: center;
  padding: 35px 0px 25px 0px;
}
.imgContainer img {
  margin-right: 20px;
}

.titleContainer {
  text-align: center;
}
.titleContainer p {
  margin-bottom: 0;
}
.modalTitle {
  font-weight: 700;
  font-size: 22px;
}
.modalSubtitle {
  font-weight: 500;
  font-size: 16px;
}

.buttonContainer {
  display: flex;
  gap: 8px;
}

.modal:focus-within {
  outline: none !important;
}
.modal h3 {
  font-size: xx-large;
  font-weight: bold;
}

@media (max-width: 992px) {
  .modal {
    top: auto;
    bottom: 0;
    width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 1200px) {
  .modal {
    width: 90% !important;
  }
}
@media (max-width: 575px) {
  .modal {
    border-radius: 0px !important;
    min-width: 100% !important;
    margin-top: 0px !important;
    min-height: 100% !important;
    max-height: 100% !important;
    top: 50% !important;
  }
}
@media (max-width: 450px) {
  .modal {
    width: 90%;
    min-width: 300px;
  }
}
.backToAuction {
  color: white;
  background: var(--brand-pink);
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  width: max-content;
  align-items: center;
  font-size: 1.25rem;
  width: 34px;
  height: 34px;
  display: flex;
  align-self: center;
  border: 1.5px solid #e02ecf;
  padding: 4px;
  border-radius: 10px;
}

@media (min-width: 577px) {
  .backToAuction svg {
    height: 2rem;
    width: 2rem;
  }
}
