.container {
  margin-top: 2rem;
}
.container h1 {
  font-size: 25px;
  font-weight: bold;
  color:#F5EEE6;
}
.card {
  margin: 2rem 0;
  padding: 10px !important;
  border: 1px rgba(0, 0, 0, 0.1) solid;
}
.card h2 {
  font-size: 14px;
  font-weight: bold;
}
.card p {
  font-size: 12px;
  font-weight: medium;
}

.step {
  margin: 1rem 0;
}
.step h2 {
  font-size: 22px;
  font-weight: bold;
  color: #F5EEE6;
}

.step p {
  font-size: 16px;
  font-weight: medium;
  color: #F5EEE6;
}
.step p a {
  color: #F8B73E !important;
  font-weight: bold;
  text-decoration: underline;
}
.step button {
  margin: 1rem 1rem 1rem 0;
}
