.sideCards {
  position: sticky;
  top: 4rem;
  align-self: flex-start;
}
.swiper {
  z-index: 0;
  width: 100%;
}

@media (max-width: 1200px) {
  .sideCards {
    display: flex;
    padding: 0;
    gap: 8px;
    margin-top: -16px;

    position: relative;
    top: inherit;
    align-self: inherit;
  }
  .carousel {
    display: flex;
    overflow-x: scroll;
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .breakOut {
    width: 100vw;
    max-width: none !important;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
    height: max-content !important;
  }
}
