.sideCardBody {
  font-size: 14px;
  margin-bottom: 0;
}
.title {
  font-size: 18px;
  margin-bottom: 0;
}
.subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: #A3A3A4;
}
.subtitle b div {
  display: inline;
}
.icon {
  width: 44px;
  height: 44px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.purpleText {
  color: var(--text-color);
}
.purpleIcon {
  background: var(--brand-purple-hint);
  border: 1px solid var(--brand-purple-semi-transparent);
}
.purpleIcon svg {
  color: var(--brand-purple);
}
.divider {
  margin: 18px 0px;
}

.totalVotes {
  color: var(--brand-gray);
}
.votingInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.votingBar {
  border-radius: 50px;
  margin: 12px 0px;
  background: var(--brand-purple-hint);
}
